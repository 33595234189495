<template>
<div class="form-row subcontainer py-4 font-cu">
  <template v-if="is_virus || test_count < max_bacteria">
    <div class="col-12 d-flex justify-content-between">
      <h4 class="mr-3 mb-3">{{ `เพิ่ม${is_virus? DisinfectantTestType.VIRUS : DisinfectantTestType.BACTERIA}เพื่อทดสอบ`}}</h4>
      <button v-if="is_CP"
              class="btn btn-danger align-self-start"
              @click="use_CP()">
        ใช้ มาตรฐาน CP Protocol
      </button>
    </div>

    <div class="col-5">
      <FormSelect
        v-if="is_virus"
        color="yellow"
        label="name"
        form-label="เลือกไวรัส"
        placeholder="เลือก..."
        :clearable="false"
        :searchable="false"
        :options="disinfectant_virus_list"
        :get-option-label="option => option.display_name"
        v-model="virus" />
      <FormInput
        v-else
        :input-class="`input-${color}`"
        label="ชื่อแบคทีเรีย"
        type="text"
        v-model.trim="bacteria_name" />
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>ระดับความเข้มข้น</label>
        <div class="row">
          <div class="col-5 pl-0">
            <div class="d-flex mb-2">
              <input  type="text"
                      class="form-control mr-2"
                      :class="`input-${color}`"
                      placeholder="ex. undiluted, 1:200, 1:400, 20%"
                      @keyup.enter="add_dilution()"
                      v-model="new_dilution">
              <button class="btn btn-secondary form-control btn-add-disinfectant"
                      :disabled="new_dilution_invalid"
                      @click="add_dilution()">
                <i class="fas fa-plus btn-inner-icon mr-0"></i>
              </button>
            </div>
          </div>
          <div class="col">
            <div class="d-flex flex-wrap">
              <div  v-for="(dilution, idx) of dilution_arr"
                    :key="idx"
                    class="mr-2 mb-2">
                <button class="btn form-control btn-disinfectant"
                        :class="`btn-${color}`"
                        @click="delete_dilution(idx)">
                  {{ dilution }}
                  <i class="fas fa-times btn-inner-icon mr-0" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="is_CP" class="col-12">
      <div class="form-group">
        <label>ระยะหลังการเจือจาง</label>
        <div class="row">
          <div class="col-5 pl-0">
            <div class="d-flex mb-2">
              <input  type="number"
                      class="form-control mr-2 text-right"
                      :class="`input-${color}`"
                      placeholder="ex. 1, 2, 7"
                      @keyup.enter="add_dilution_time()"
                      v-model="new_dilution_time">
              <FormSelect
                class="mr-2 mb-0 contact-time-select"
                :disabled="true"
                :color="color"
                :clearable="false"
                :options="['วัน']"
                :value="'วัน'" />
              <button class="btn btn-secondary form-control btn-add-disinfectant"
                      :disabled="new_dilution_time_invalid"
                      @click="add_dilution_time()">
                <i class="fas fa-plus btn-inner-icon mr-0"></i>
              </button>
            </div>
          </div>
          <div class="col">
            <div class="d-flex flex-wrap">
              <div  v-for="(dilution_time, idx) of dilution_time_arr"
                    :key="idx"
                    class="mr-2 mb-2">
                <button class="btn form-control btn-disinfectant"
                        :class="`btn-${color}`"
                        @click="delete_dilution_time(idx)">
                  {{ dilution_time }}
                  <i class="fas fa-times btn-inner-icon mr-0" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>ระยะสัมผัสเชื้อ</label>
        <div class="row">
          <div class="col-5 pl-0">
            <div class="d-flex mb-2">
              <input  type="number"
                      class="form-control mr-2 text-right"
                      :class="`input-${color}`"
                      placeholder="ex. 1, 5, 10"
                      @keyup.enter="add_contact_time()"
                      v-model="new_contact_time_num">
              <FormSelect
                class="mr-2 mb-0 contact-time-select"
                :color="color"
                :clearable="false"
                :options="['วินาที', 'นาที']"
                v-model="new_contact_time_unit" />
              <button class="btn btn-secondary form-control btn-add-disinfectant"
                      :disabled="new_contact_time_invalid"
                      @click="add_contact_time()">
                <i class="fas fa-plus btn-inner-icon mr-0"></i>
              </button>
            </div>
          </div>
          <div class="col">
            <div class="d-flex flex-wrap">
              <div  v-for="(contact_time, idx) of contact_time_arr"
                    :key="idx"
                    class="mr-2 mb-2">
                <button class="btn form-control btn-disinfectant"
                        :class="`btn-${color}`"
                        @click="delete_contact_time(idx)">
                  {{ contact_time }}
                  <i class="fas fa-times btn-inner-icon mr-0" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group col-5 mt-3">
      <button class="btn btn-primary btn-block"
              :class="`btn-${color}`"
              :disabled="is_virus && !valid_form_virus() || is_CP && !valid_form_bacteria()"
              @click="parse_output()">
        เพิ่มรายการทดสอบ
      </button>
    </div>
    <div v-if="!is_virus" class="form-group col-12 d-flex mb-0">
      <h5 class="text-medium">
        (สามารถเพิ่มแบคทีเรียได้อีก
        <span class="mx-1" :class="`text-${color}`">{{ max_bacteria - test_count }}</span>
        รายการ)
      </h5>
    </div>

  </template>
  <div v-else class="form-group col-12 mb-0">
    <h5 class="text-medium">ท่านได้เพิ่มรายการแบคทีเรียครบถึง 5 รายการแล้ว</h5>
  </div>
</div>
</template>

<script>
import uniq from 'lodash/uniq'

import { DISINFECTANT_TEST_METHODS } from '@/graphql/tests'
import { DisinfectantTestType } from '@/enum'
import { COLORS } from '@/const'

export default {
  name: 'form-disinfectant-test-input',
  props: {
    test_type: {
      type: String,
      required: true
    },
    test_count: {
      type: Number,
      default: 0
    }
  },
  watch: {
    test_type () {
      this.clear_all()
    }
  },
  data () {
    return {
      virus: '',
      bacteria_name: '',
      dilution_arr: [],
      contact_time_arr: [],
      dilution_time_arr: [],
      new_dilution: '',
      new_dilution_time: '',
      new_contact_time_num: '',
      new_contact_time_unit: 'นาที',
      max_bacteria: 5,
    }
  },
  computed: {
    is_virus () {
      return this.test_type === DisinfectantTestType.VIRUS
    },
    is_bacteria () {
      return this.test_type === DisinfectantTestType.BACTERIA
    },
    is_CP () {
      return this.test_type === DisinfectantTestType.BACTERIA_CP
    },
    color () {
      return COLORS.DISINFECTANT_TEST_TYPE[this.test_type]
    },
    new_dilution_invalid () {
      return !/^([1-9]\d*:(0|[1-9]\d*)|([1-9][0-9]?|100)%|undiluted)$/.test(this.new_dilution)
    },
    new_dilution_time_invalid () {
      return !this.new_dilution_time
    },
    new_contact_time_invalid () {
      return !this.new_contact_time_num || !this.new_contact_time_unit
    }
  },
  methods: {
    clear_all () {
      this.virus = ''
      this.bacteria_name = ''
      this.dilution_arr = []
      this.contact_time_arr = []
      this.dilution_time_arr = []
      this.new_dilution =  ''
      this.new_dilution_time =  ''
      this.new_contact_time_num =  ''
      this.new_contact_time_unit =  'นาที'
    },
    add_dilution () {
      if (this.new_dilution_invalid) {
        return
      }
      this.dilution_arr.push(this.new_dilution)
      this.new_dilution = ""
    },
    delete_dilution (idx) {
			this.dilution_arr.splice(idx, 1)
		},
    add_dilution_time () {
      if (this.new_dilution_time_invalid) {
        return
      }
      this.dilution_time_arr.push(`${this.new_dilution_time} วัน`)
      this.new_dilution_time = ""
    },
    delete_dilution_time (idx) {
			this.dilution_time_arr.splice(idx, 1)
		},
    add_contact_time () {
      if (this.new_contact_time_invalid) {
        return
      }
      this.contact_time_arr.push(`${this.new_contact_time_num} ${this.new_contact_time_unit}`)
      this.new_contact_time_num = ""
    },
    delete_contact_time (idx) {
			this.contact_time_arr.splice(idx, 1)
		},
    parse_output () {
      this.dilution_arr = uniq(this.dilution_arr)
      this.contact_time_arr = uniq(this.contact_time_arr)
      if (this.is_virus) {
        this.parse_output_virus()
      } else if (this.is_CP) {
        this.dilution_time_arr = uniq(this.dilution_time_arr)
        this.parse_output_CP()
      } else {
        this.parse_output_bacteria()
      }
    },
    parse_output_virus () {
      if (!this.valid_form_virus()) return

      const test_count = this.dilution_arr.length * this.contact_time_arr.length
      const price = this.virus.price * test_count

      const parsed_output = {}
      parsed_output[this.virus.test_key] = {
        display_name: this.virus.display_name,
        dilutions: [...this.dilution_arr],
        contact_times: [...this.contact_time_arr],
        cell_name: this.virus.cell_name,
        price: price,
        test_count: test_count
      }
      this.virus = null
      this.$emit('add', parsed_output)
    },
    parse_output_bacteria () {
      if (!this.valid_form_bacteria()) return

      const test_count = this.dilution_arr.length * this.contact_time_arr.length
      const price = 3000 * test_count

      const parsed_output = {}
      parsed_output[this.bacteria_name] = {
        display_name: this.bacteria_name,
        dilutions: [...this.dilution_arr],
        contact_times: [...this.contact_time_arr],
        price: price,
        test_count: test_count
      }
      this.$emit('add', parsed_output)
      this.bacteria_name = ''
    },
    parse_output_CP () {
      if (!this.valid_form_CP()) return

      const test_count = this.dilution_arr.length * this.contact_time_arr.length * this.dilution_time_arr.length
      const price = 3000 * test_count

      const parsed_output = {}
      parsed_output[this.bacteria_name] = {
        display_name: this.bacteria_name,
        dilutions: [...this.dilution_arr],
        contact_times: [...this.contact_time_arr],
        dilution_times: [...this.dilution_time_arr],
        price: price,
        test_count: test_count
      }
      this.$emit('add', parsed_output)
      this.bacteria_name = ''
    },

    valid_form_virus () {
      return (
        this.virus &&
        this.dilution_arr.length > 0 &&
        this.contact_time_arr.length > 0
      )
    },
    valid_form_bacteria () {
      return (
        this.bacteria_name &&
        this.dilution_arr.length > 0 &&
        this.contact_time_arr.length > 0
      )
    },
    valid_form_CP () {
      return (
        this.bacteria_name &&
        this.dilution_arr.length > 0 &&
        this.contact_time_arr.length > 0 &&
        this.dilution_time_arr.length > 0
      )
    },
    use_CP () {
      this.dilution_arr = ['1:200', '1:400']
      this.dilution_time_arr = ['1 วัน', '2 วัน', '7 วัน']
      this.contact_time_arr = ['30 วินาที', '2 นาที', '60 นาที']
      this.bacteria_name = 'E.Coli'
      this.parse_output()

      this.bacteria_name = 'Salmonella'
      this.parse_output()

      this.clear_all()
    }
  },
  apollo: {
    disinfectant_virus_list: {
      query: DISINFECTANT_TEST_METHODS,
      update: data => data.test_method_disinfectant_virus,
      skip () {
        return !this.is_virus
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hint-box {
  width: 250px;
  bottom: 43px;
  right: 0px;
  transform-origin: 100% 100%;
}
.btn-add-disinfectant {
  width: 2em;
}
.contact-time-select {
  width: 12em;
}
</style>