<template>
<div class="preview-bg">
	<div class="d-flex flex-column w-100 align-items-center fill-height scroll-container">
		<div class="d-flex btn-panel justify-content-between">
			<button class="back-to-form btn btn-lg back-btn btn-transparent"
							@click="$emit('back')">
				<i class="fas fa-chevron-left btn-inner-icon-lg text-left mr-0" />กลับไปแก้ไข
			</button>
			<button class="submit btn btn-lg btn-success"
							@click="$emit('submit')">
				<i class="fas fa-paper-plane btn-inner-icon-lg text-left mr-0" />
				{{ edit_mode? 'ยืนยันและบันทึก' : 'ยืนยันและส่งตัวอย่าง' }}
			</button>
		</div>
		<SubmissionForm
			v-if="processed_submission"
			iframe_key="review-iframe"
			:show_form="true"
			:submission="processed_submission" />

	</div>
</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

import { ProcessSubmission, GroupTestMethods } from '@/util'
import { GENERAL_TEST_METHODS } from '@/graphql/tests'
import { SubmissionType } from '@/enum'

export default {
	name: 'review-submission',
	props: {
		submission: {
			type: Object,
			required: true
		},
		user: {
			type: Object,
			required: true
		},
		sample_owner: {
			type: Object,
			required: true
		},
		invoice_to: {
			type: Object,
			required: true
		},
		edit_mode: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		is_disinfectant () {
			return this.submission.submission_type === SubmissionType.DISINFECTANT
		},
	},
	data () {
		return {
			processed_submission: null,
			saved_document_title: null
		}
	},
	mounted () {
		if (this.is_disinfectant) {
			this.render_submission_form()
		}
	},
	methods: {
		render_submission_form () {
			this.processed_submission = this.process_submission()
		},
		save_pdf () {
			this.saved_document_title = document.title
			document.title = `CUVDL-Submission-Form`
			const iframe = document.getElementById('review-iframe')
			iframe.contentWindow.print();
			document.title = this.saved_document_title
		},
		process_submission () {
			const processed = cloneDeep(this.submission)
			processed.submitter = { ...this.user.primary_contact }
			processed.sample_owner = { ...this.sample_owner }
			processed.invoice_to = { ...this.invoice_to }
			return ProcessSubmission(processed, this.test_methods)
		},
	},	
	apollo: {
		test_methods: {
			query: GENERAL_TEST_METHODS,
			update: data => GroupTestMethods(data.test_method_general),
			result (res) {
				if (res?.data?.test_method_general) {
					this.render_submission_form()
				}
			},
			skip () {
				return this.is_disinfectant
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.preview-bg {
	position: fixed;
	top: 0; left: 0; right: 0; bottom: 0;
	overflow: hidden;
	background: rgba($black, .55);
	z-index: 1000;
	@include backdrop-blur;
}

.btn-panel {
	width: 893px;
	max-width: 893px;
	position: fixed;
	padding: 1rem;
	background: rgba($black, .4);
	border-bottom-left-radius: .5em;
	border-bottom-right-radius:.5em;
	@include backdrop-blur;
}
.back-to-form {
	color: $light;
	&:hover {
		color: $default;
	}
}
</style>